import React, { useEffect } from "react";
import { sort, setting } from "../../images/images";
import styles from "./HistoryOfStacking.module.css";
import TokenHistory from "./TokenHistory/TokenHistory";
import { useAccount } from "wagmi";
import axios from "axios";

const HistoryOfStacking = ({ showRef }) => {




  let { address } = useAccount();
  const [history, setHistory] = React.useState([]);
  const [copy, setCopy] = React.useState("Copy Referral Link");

  const copied = () => {
    setCopy("Copied");
    setTimeout(() => {
      setCopy("Copy Referral Link");
    }, 1000);
  };

  let url = "https://api.thegraph.com/subgraphs/name/civa/uns-100-agre";

  let query = `  {
    allActivities(where: {sender: "${(address)}" }) {
      id
      type
      sender
      amount
      blockNumber
    }  
  }
`;


  const fetchHistory = async () => {
    if (!address) return;


    try {
      let { data } = await axios.post(url, { query });
      // console.log(data)
      setHistory(data?.data.allActivities)
      // console.log(data)
    } catch (e) {
      console.log(e)
    }

    // let data = await fetch(url, {
    //   method: 'POST',
    //   headers: {
    //     'content-type': 'application/json'
    //     // Other headers, including `authorization` or `x-api-key`
    //   },
    //   body: JSON.stringify({
    //     query
    //   })
    // })

    // let res = await data.json();
    // console.log(res)
  };

  function copyToClipboard(text) {
    // Create a temporary textarea element
    const textarea = document.createElement('textarea');
    textarea.value = text;
    textarea.setAttribute('readonly', '');
    textarea.style.position = 'absolute';
    textarea.style.left = '-9999px';

    // Append the textarea element to the document body
    document.body.appendChild(textarea);

    // Copy the contents of the textarea to the clipboard
    textarea.select();
    document.execCommand('copy');

    // Remove the temporary textarea element
    document.body.removeChild(textarea);
  }


  React.useEffect(() => {
    fetchHistory();
  }, [address]);

  // const getHistory = async () => {
  //   let url = `https://api.uns.technology/activity/user/${address}`
  //   let data = await fetch(url)
  //   let res = await data.json()
  //   setHistory(res)
  // }

  useEffect(() => {
    setInterval(() => {
      fetchHistory();
    }, 5000);
  }, []);
  // const history = [
  //   {
  //     operation: "stake",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "Unstake",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "Purchase",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "stake",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "Unstake",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "Purchase",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "stake",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "Unstake",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   {
  //     operation: "Purchase",
  //     amount: 214474,
  //     time: 1679927783475,
  //   },
  //   // {
  //   //   operation: "Referral - Bonus",
  //   //   amount: 214474,
  //   //   time: 1679927783475,
  //   // },
  // ];
  return (
    <div className={styles.historyOfStackingWrapper}>
      <div className={styles.spaceBetween}>
        <p className={styles.title}>History of staking</p>
        <div className={styles.buttonContainer}>
          {showRef && <div className={styles.filterAndShort}>
            {" "}
            <img src={sort} alt="#" className={styles.icon} />{" "}
            <span
              onClick={() => {
                let ref = `https://minisale.uns.technology/${address}`
                navigator.clipboard.writeText(ref);
                copyToClipboard(ref);
                copied();
              }}
              className={styles.text}>{copy}</span>
          </div>}
          <div className={styles.settings}>
            <img src={setting} alt="#" className={styles.icon} />
          </div>
        </div>
      </div>
      <TokenHistory history={history} />
    </div>
  );
};

export default HistoryOfStacking;
