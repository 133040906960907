import React, { useState } from "react";
import { BsInstagram, BsFacebook, BsTwitter, BsDiscord, BsTelegram, BsYoutube } from "react-icons/bs";
import { GrLinkedinOption } from "react-icons/gr";

import { logo, category } from "../../images/images";
import styles from "./styles.module.css";

const Footer = () => {
  const socialMedias = [
    { icon: <BsInstagram className={styles.socialIcon} />, to: "#" },
    { icon: <BsFacebook className={styles.socialIcon} />, to: "#" },
    { icon: <BsTwitter className={styles.socialIcon} />, to: "#" },
    { icon: <GrLinkedinOption className={styles.socialIcon} />, to: "#" },
    { icon: <BsDiscord className={styles.socialIcon} />, to: "#" },
    { icon: <BsTelegram className={styles.socialIcon} />, to: "https://t.me/unstoken" },
    { icon: <BsYoutube className={styles.socialIcon} />, to: "https://www.youtube.com/channel/UCgkR5Guy3kNWSESdD_NfAeg" },

  ];
  const footerItems = [
    {
      heading: "Company",
      items: [
        { name: "Homepage", to: "#" },
        { name: "Buy UNS", to: "https://sale.uns.technology" },

      ],
    },
    // {
    //   heading: "Development",
    //   items: [
    //     { name: "Buy & Sell Crypto", to: "#" },
    //     { name: "Wallet", to: "#" },
    //     { name: "Card", to: "#" },
    //     { name: "Dashboard", to: "#" },
    //     { name: "Trading", to: "#" },
    //     { name: "Portfolio", to: "#" },
    //   ],
    // },
    {
      heading: "Support",
      items: [
        { name: "support@uns.technology", to: "mailto:support@uns.technology" },

      ],
    },
    {
      heading: "Resources",
      items: [
        { name: "Whitepaper", to: "https://uns.technology/whitepaper.pdf?v=14" },

      ],
    },
  ];

  return (
    <>
      <section className={styles.footerContainer}>
        <div className="container">
          <div className={styles.footer}>
            <div className={styles.logoAndSocials}>
              <div className={styles.logoContainer}>
                <img src={logo} alt="#" className={styles.logo} />
                <p className={styles.logoText}></p>
              </div>
              <div className={styles.languageContainer}>
                <span className={styles.language}>English</span>
                <img src={category} alt="#" className={styles.icon} />
              </div>
              <div className={styles.socialContainer}>
                <div className={styles.socials}>
                  {socialMedias.map((el, i) => (
                    <a
                      href={el.to}
                      key={i}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.social}
                    >
                      {el.icon}
                    </a>
                  ))}
                </div>
              </div>
              <p className={styles.copyRight}>Copyright 2023 © UNS</p>
            </div>
            <div className={styles.footerItems}>
              {footerItems.map((el, i) => (
                <div className={styles.footerItem} key={i}>
                  <h5 className={styles.heading}>{el.heading}</h5>
                  {el.items.map((el, i) => (
                    <a
                      href={el.to}
                      key={i}
                      target="_blank"
                      rel="noreferrer"
                      className={styles.text}
                    >
                      {el.name}
                    </a>
                  ))}
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Footer;
