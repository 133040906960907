import React from "react";
import HistoryOfStacking from "../../components/HistoryOfStacking/HistoryOfStacking";
import Staking from "../../components/Staking/Staking";
import { SaleStaking } from "../../components/Staking/Staking";
import styles from "./styles.module.css";

const Home = () => {
  const [showRef, setShowRef] = React.useState(false);
  return (
    <div className={styles.wrapper}>
      <div className="container">
        {" "}
        <div className={styles.home}>
          <HistoryOfStacking showRef={showRef} />
          <Staking setShowRef={setShowRef} />
        </div>
      </div>
    </div>
  );
};

// export const Home2 = () => {
//   return (
//     <div className={styles.wrapper}>
//       <div className="container">
//         {" "}
//         <div className={styles.home}>
//           <HistoryOfStacking />
//           <SaleStaking />
//         </div>
//       </div>
//     </div>
//   );
// };

export default Home;
