import React, { useEffect, useState } from "react";
import { uns } from "../../../images/images";
import styles from "./StakeAmount.module.css";

const StakeAmount = ({ rate, unsBalance, setStakeInput, stakeInput }) => {
  const [unsPrice, setUnsPrice] = useState(0);
  const getUnsPrice = async () => {
    let url = "https://api.coingecko.com/api/v3/simple/price?ids=uns-token&vs_currencies=usd"
    const res = await fetch(url)
    const data = await res.json()
    setUnsPrice(data["uns-token"].usd)

  }

  useEffect(() => {
    getUnsPrice();
  }, [unsPrice]);
  const [amount, setAmount] = useState();
  return (
    <div>
      <div className={styles.stakeAmountContainer}>
        <div className={styles.spaceBetween}>
          <p className={styles.title}>Enter UNS Amount</p>
          <p className={styles.text}>Please enter amount</p>
        </div>{" "}
        <div className={styles.valueWrapper}>
          <div className={styles.form}>
            <p className={styles.currency}>UNS</p>
            <input
              type="text"
              placeholder="0.0"
              className={styles.input}
              value={stakeInput}
              onChange={(e) => {

                setAmount(e.target.value);
                setStakeInput(e.target.value)


              }}
            />
            <p
              onClick={() => { setStakeInput(unsBalance); }}
              className={[styles.ubs, styles.subText].join(" ")}>MAX</p>
          </div>
          <div className={styles.unsContainer}>
            <img src={uns} alt="#" className={styles.image} />
            <span className={[styles.uns, styles.subText].join(" ")}>uns</span>
          </div>
        </div>
        <div
          className={[styles.spaceBetween, styles.availableContainer].join(" ")}
        >
          <p className={styles.title}>
            UNS Bal:{" "}
            <span className={styles.value}>
              {unsBalance}
            </span>{" "}
          </p>

          <p className={styles.text}>
            ≈ {parseInt(unsBalance * unsPrice).toFixed(2)} USDT
          </p>

        </div>{" "}
      </div>
    </div >
  );
};

export default StakeAmount;
