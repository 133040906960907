import React, { useEffect, useState } from "react";
import { lock, activeLock, unlock, activeUnlock } from "../../images/images";
import Duration from "./Duration/Duration";
import Rate from "./Rate/Rate";
import StakeAmount from "./StakeAmount/StakeAmount";
import styles from "./style.module.css";
import { CONTRACTS, erc20abi, stakingabi, presaleabi } from "../../constants/contracts";
import { useContractWrite, usePrepareContractWrite } from "wagmi";
import { useContractRead } from "wagmi";
import { useAccount, useSigner } from "wagmi";
import { ethers } from "ethers";
import { useWeb3Modal } from "@web3modal/react";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useParams } from "react-router-dom";

const Staking = ({ setShowRef }) => {
  let stake1 = "0x54E1A93D40502aA0a3E2D57B2FdCb52DF037f3c6";
  // let stake2 = "0x54E1A93D40502aA0a3E2D57B2FdCb52DF037f3c6";
  // let stake3 = "0x54E1A93D40502aA0a3E2D57B2FdCb52DF037f3c6"
  // let stake4 = "0x54E1A93D40502aA0a3E2D57B2FdCb52DF037f3c6"


  const [activeContract, setActiveContract] = useState(
    stake1
  );
  const [stakeInput, setStakeInput] = useState("");
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  const [ref, setRef] = useState("");
  let { data: signer } = useSigner()

  const [allowance, setAllowance] = useState(0);
  const [unsBalance, setUnsBalancw] = useState(0);
  const [stakingBalance, setStakingBalance] = useState(0);
  const [active, setActive] = useState(0);
  //
  const [stakingBal2, setStakingBal2] = useState(0);
  const [stakingBal3, setStakingBal3] = useState(0);
  const [stakingBal4, setStakingBal4] = useState(0);

  const [allowance2, setAllowance2] = useState(0);
  const [allowance3, setAllowance3] = useState(0);
  const [allowance4, setAllowance4] = useState(0);


  const [earning, setEarning] = useState(0);


  // let bscProvider = "https://endpoint.solidhash.io/mainnet/bsc/3MLn5r0ugwMkELTKl0x85v"
  let bscProvider = "https://bsc-dataseed.binance.org/"
  let bsc = new ethers.providers.JsonRpcProvider(bscProvider)


  const initiateStake = async (amount, _active) => {
    let contract = new ethers.Contract(_active, stakingabi, signer)
    let tx = await contract.stake(ethers.utils.parseUnits(amount, 18))
    let reciept = await tx.wait();
    if (reciept.status === 1) {
      init();
    }
  }

  const initializeApprove = async (_active) => {
    try {
      let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, signer)
      let tx = await contract.approve(_active, ethers.utils.parseUnits('1000000000000000000000000000000', 18))
      let reciept = await tx.wait();
      if (reciept.status === 1) {
        init();
      }
    } catch (e) {
    }
  }

  const initializeUnstake = async (_active, _amount) => {
    let contract = new ethers.Contract(_active, stakingabi, signer)
    let tx = await contract.unstake(ethers.utils.parseEther(_amount))
    let reciept = await tx.wait();
    if (reciept.status === 1) {
      init();
    }
  }

  const initializeWithdraw = async (_active) => {
    if (earning === "0.0") {
      toast.error("No earnings to withdraw", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
      });
      alert("No earnings to withdraw");
      return
    }
    let contract = new ethers.Contract(_active, stakingabi, signer)
    let tx = await contract.withdrawEarnings()
    let reciept = await tx.wait();
    if (reciept.status === 1) {
      init();
    }
  }


  //allowance
  const getAllowance = async () => {
    let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, bsc)
    let allowance = await contract.allowance(address, stake1)
    setAllowance(String(allowance))
  }

  // const getAllowance2 = async () => {
  //   let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, bsc)
  //   let allowance = await contract.allowance(address, stake2)
  //   setAllowance2(String(allowance))
  // }

  // const getAllowance3 = async () => {
  //   let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, bsc)
  //   let allowance = await contract.allowance(address, stake3)
  //   setAllowance3(String(allowance))
  // }

  // const getAllowance4 = async () => {
  //   let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, bsc)
  //   let allowance = await contract.allowance(address, stake4)
  //   setAllowance4(String(allowance))
  // }
  let min = 99;
  const getStakingBal = async () => {
    let contract = new ethers.Contract(stake1, stakingabi, bsc)
    let balance = await contract.stakes(address);

    setStakingBalance(ethers.utils.formatEther(balance))
  }
  // const getStakingBal2 = async () => {
  //   let contract = new ethers.Contract(stake2, stakingabi, bsc)
  //   let balance = await contract.stakes(address);

  //   setStakingBal2(ethers.utils.formatEther(balance))
  // }
  // const getStakingBal3 = async () => {
  //   let contract = new ethers.Contract(stake3, stakingabi, bsc)
  //   let balance = await contract.stakes(address);

  //   setStakingBal3(ethers.utils.formatEther(balance))
  // }
  // const getStakingBal4 = async () => {
  //   let contract = new ethers.Contract(stake4, stakingabi, bsc)
  //   let balance = await contract.stakes(address);

  //   setStakingBal4(ethers.utils.formatEther(balance))
  // }

  const [unlock1, setUnlock1] = useState(0);
  const [unlock2, setUnlock2] = useState(0);
  const [unlock3, setUnlock3] = useState(0);
  const [unlock4, setUnlock4] = useState(0);

  const getStakeTime1 = async () => {
    let currentTime = Date.now();
    let contract = new ethers.Contract(stake1, stakingabi, bsc);
    let duration = await contract.stakeTime();
    let passed = await contract.getStakeDuration(address);
    let pending = duration - passed;
    //convert timestamp to date
    let miliseconds = pending * 1000;
    let newTime = currentTime + miliseconds

    if (Date.now() > new Date(newTime)) {
      setUnlock1(0)
    } else {
      setUnlock1(newTime)
    }

  }
  // const getStakeTime2 = async () => {
  //   let currentTime = Date.now();
  //   let contract = new ethers.Contract(stake2, stakingabi, bsc);
  //   let duration = await contract.stakeTime();
  //   let passed = await contract.getStakeDuration(address);
  //   let pending = duration - passed;
  //   //convert timestamp to date
  //   let miliseconds = pending * 1000;

  //   let newTime = currentTime + miliseconds
  //   if (Date.now() > new Date(newTime)) {
  //     setUnlock2(0)
  //   }
  //   else {
  //     setUnlock2(newTime)
  //   }
  // }
  // const getStakeTime3 = async () => {
  //   let currentTime = Date.now();
  //   let contract = new ethers.Contract(stake3, stakingabi, bsc);
  //   let duration = await contract.stakeTime();
  //   let passed = await contract.getStakeDuration(address);
  //   let pending = duration - passed;
  //   //convert timestamp to date
  //   let miliseconds = pending * 1000;

  //   let newTime = currentTime + miliseconds

  //   if (Date.now() > new Date(newTime)) {
  //     setUnlock3(0)
  //   }
  //   else {
  //     setUnlock3(newTime)
  //   }

  // }
  // const getStakeTime4 = async () => {
  //   let currentTime = Date.now();
  //   let contract = new ethers.Contract(stake4, stakingabi, bsc);
  //   let duration = await contract.stakeTime();
  //   let passed = await contract.getStakeDuration(address);
  //   let pending = duration - passed;
  //   //convert timestamp to date
  //   let miliseconds = pending * 1000;

  //   let newTime = currentTime + miliseconds
  //   if (Date.now() > new Date(newTime)) {
  //     setUnlock4(0)
  //   }
  //   else {
  //     setUnlock4(newTime)
  //   }
  // }



  let hdate;

  const getUnlockDate = () => {
    if (active === 0) {
      if (Date.now() > new Date(unlock1)) {
        return "Unlocked"
      }
      return new Date(unlock1).toLocaleString();
    } else if (active === 1) {
      if (Date.now() > new Date(unlock2)) {
        return "Unlocked"
      }
      return new Date(unlock2).toLocaleString();
    } else if (active === 2) {
      if (Date.now() > new Date(unlock3)) {
        return "Unlocked"
      }
      return new Date(unlock3).toLocaleString();
    } else if (active === 3) {
      if (Date.now() > new Date(unlock4)) {
        return "Unlocked"
      }
      return new Date(unlock4).toLocaleString();
    }
  }

  const date = getUnlockDate();



  const setShowButton = async () => {
    let bal = parseInt(stakingBal2) + parseInt(stakingBal3) + parseInt(stakingBal4) + parseInt(stakingBalance);
    if (bal > min) {
      setShowRef(true)
    } else {
      setShowRef(false)
    }
  }


  const getUnsBalance = async () => {
    let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, bsc)
    let saleContract = new ethers.Contract(CONTRACTS.presale, presaleabi, bsc)
    let saleBalance = await saleContract.unsBalance(address)
    let balance = await contract.balanceOf(address);
    let total = balance.add(saleBalance)
    return ethers.utils.formatEther(total)
  }

  const getEarning = async () => {
    let contract = new ethers.Contract(activeContract, stakingabi, bsc);
    let earning = await contract.calculateEarnings(address);
    let rewards = await contract.stakeRewards(address)
    let total = earning.add(rewards)
    setEarning(ethers.utils.formatEther(total));
    return ethers.utils.formatEther(earning);
  }


  let { id } = useParams()



  // I just add this
  const [stakeButtonActive, setStakeButtonActive] = useState(true);
  // also change code on line no 128 and 145 for switching


  const SubmitEntry = async () => {

    if (!stakeInput) {
      toast.error("Please enter stake amount", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      alert("Please enter stake amount");
      return
    }
    if (stakeInput < 100) {
      toast.error("Minimum stake amount is 100 UNS", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      alert("Minimum stake amount is 100 UNS");
      return
    }
    if (parseInt(stakeInput) > parseInt(unsBalance)) {
      toast.error("Insufficient balance", {
        position: toast.POSITION.BOTTOM_CENTER,
        autoClose: 1000,
      });
      alert("Insufficient Balance");
      return
    }
    if (stakeButtonActive) {
      initiateStake(stakeInput, activeContract);
    } else if (!stakeButtonActive) {

      initializeUnstake(activeContract, stakeInput);
    }
    setStakeInput("");
  }

  const init = async () => {
    const unsBal = await getUnsBalance();
    setUnsBalancw(String(unsBal));

    getEarning();
    getStakeTime1();
    // getStakeTime2();
    // getStakeTime3();
    // getStakeTime4();


    getStakingBal();
    // getStakingBal2();
    // getStakingBal3();
    // getStakingBal4();
    buttonTitle();
    setShowButton();

    getAllowance();
    // getAllowance2();
    // getAllowance3();
    // getAllowance4();

    if (!id) {
      let id = "0x7Cb99eDC0bc6FBa110302e39b7fE6Aaf15f4bC8B";
      setRef(id);
    } else {
      setRef(id);
    }


  };

  // useEffect(() => {
  //   init();
  // }, [, stakingBalance, stake]);
  useEffect(() => {
    init();
  }, [address, stakeButtonActive, unsBalance, stakingBalance, allowance, activeContract, active]);


  const buttonTitle = () => {
    if (stakeButtonActive) {
      if (!address) {
        return {
          title: "Connect Wallet",
          func: open,
        };
      } else if (
        active == 0 && allowance == "0" || active == 1 && allowance2 == "0" || active == 2 && allowance3 === "0" || active == 3 && allowance4 === "0"
      ) {
        return {
          title: "Approve",
          func: () => {
            initializeApprove(activeContract);
          },
        };
      } else {
        return {
          title: "Stake",
          func: () => {
            SubmitEntry();
          },
        };
      }
    } else if (!stakeButtonActive) {
      if (!address) {
        return {
          title: "Connect Wallet",
          func: open,
        };
      } else {
        return {
          title: "Unstake",
          func: () => {
            SubmitEntry();
          },
        };
      }
    }
  };


  const stakeButton = buttonTitle();

  const duration = [
    {
      day: 12,
      apy: 180,
      contract: stake1,
    },
    // {
    //   day: 6,
    //   apy: 90,
    //   contract: stake2,
    // },
    // {
    //   day: 9,
    //   apy: 135,
    //   contract: stake3,
    // },
    // {
    //   day: 12,
    //   apy: 180,
    //   contract: stake4,
    // },
  ];


  const rate = [
    {
      key: "APY:",
      value: duration[active].apy + "%",
    },
    {
      key: "Minimum stake:",
      value: "100 UNS",
    },
    {
      key: "My UNS Earnings",
      value: earning
    },
    {
      key: "Unstake Date:",
      value: date,
    },
    {
      key: "Level 1 Referral:",
      value: "7%",
    },
    {
      key: "Level 2 Referral:",
      value: "3%",
    },
  ];
  return (
    <div className={styles.stakeWrapper}>
      <div className={styles.stakeUnstake}>
        <div
          className={[
            styles.stake,
            stakeButtonActive && styles.activeLock,
          ].join(" ")}
          onClick={() => setStakeButtonActive(true)}
        // I have add this for stakeactive active
        >
          <div className={styles.lockBox}>
            <img
              src={stakeButtonActive ? activeLock : lock}
              alt="#"
              className={styles.lockIcon}
            />
          </div>
          <p className={styles.heading}>Stake</p>
        </div>
        <div
          className={[
            styles.unStake,
            !stakeButtonActive && styles.activeLock,
          ].join(" ")}
          onClick={() => setStakeButtonActive(false)}
        // I have add this for unstakeActive
        >
          <div className={styles.lockBox}>
            <img
              src={!stakeButtonActive ? activeUnlock : unlock}
              alt="#"
              className={styles.lockIcon}
            />
          </div>
          <p className={styles.heading}>Unstake</p>
        </div>
      </div>
      <Duration
        buttonTitle={buttonTitle}
        duration={duration}
        setActiveContract={setActiveContract}
        setActive={setActive}
        active={active}
      />
      <StakeAmount
        stakeInput={stakeInput}
        unsBalance={unsBalance}
        setStakeInput={setStakeInput}
      />
      <Rate
        duration={duration}
        stakeInput={stakeInput}
        stakingBalance={stakingBalance}
        active={active}
        stakingBal2={stakingBal2}
        stakingBal3={stakingBal3}
        stakingBal4={stakingBal4}


        rate={rate} />
      <button
        onClick={() => {
          stakeButton.func();
        }}
        className={styles.button}
      >
        {stakeButton.title}
      </button>
      {!stakeButtonActive && <button
        onClick={() => {
          initializeWithdraw(activeContract);
        }}
        className={styles.button}
      >
        Claim {earning} UNS
      </button>}
    </div>
  );
};




//////////////////



export const SaleStaking = () => {
  const [activeContract, setActiveContract] = useState(
    "0x886538ffDEe40045899DF9f8d4C77C23a05E73ea"
  );
  const [stakeInput, setStakeInput] = useState("");
  const { open } = useWeb3Modal();
  const { address } = useAccount();
  let { data: signer } = useSigner()

  const [allowance, setAllowance] = useState(0);
  const [unsBalance, setUnsBalancw] = useState(0);
  const [stakingBalance, setStakingBalance] = useState(0);
  const [active, setActive] = useState(0);

  let presale = "0x5b958cd26088a3c8f363bB30f1C2C444329a4Db6";
  let presaleabi = [
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "adminWithdrawUNS",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "internalType": "address",
          "name": "ref",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_currency",
          "type": "uint256"
        }
      ],
      "name": "buyUNS",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_start",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "_end",
          "type": "uint256"
        }
      ],
      "name": "extendPresale",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "forceEndPresale",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "_newUNS",
          "type": "uint256"
        }
      ],
      "name": "increaseUNSforSale",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_unsStake",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "_status",
          "type": "bool"
        }
      ],
      "name": "setUnsStake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_amount",
          "type": "uint256"
        }
      ],
      "name": "subtract",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "stateMutability": "nonpayable",
      "type": "constructor"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "unsAmount",
          "type": "uint256"
        },
        {
          "indexed": false,
          "internalType": "address",
          "name": "referrer",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "currency",
          "type": "uint256"
        }
      ],
      "name": "unsPurchased",
      "type": "event"
    },
    {
      "anonymous": false,
      "inputs": [
        {
          "indexed": true,
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "indexed": false,
          "internalType": "uint256",
          "name": "amount",
          "type": "uint256"
        }
      ],
      "name": "unsWithdrawn",
      "type": "event"
    },
    {
      "inputs": [],
      "name": "amountRaised",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "busd",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "contributions",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "endTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "maxPurchase",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "minPurchase",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "projectWallet",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "rate",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "referralEarned",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "referrer",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "startTime",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalUnsForSale",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalUnsSold",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "uns",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "unsBalance",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "unsStake",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "usdt",
      "outputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ]



  const initiateStake = async (amount, _active) => {
    let contract = new ethers.Contract(_active, stakingabi, signer)
    let tx = await contract.stake(ethers.utils.parseUnits(amount, 18), "0x80BCf591171f1e9ad5A67791592457f4b9A8771E")
    let reciept = await tx.wait();
    if (reciept.status === 1) {
      init();
    }
  }

  const initializeApprove = async (_active) => {
    let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, signer)
    let tx = await contract.approve(_active, ethers.utils.parseUnits('1000000000000000000000000000000', 18))
    let reciept = await tx.wait();
    if (reciept.status === 1) {
      init();
    }

  }

  const initializeUnstake = async (_active, _amount) => {
    let contract = new ethers.Contract(_active, stakingabi, signer)
    let tx = await contract.unstake(ethers.utils.parseEther(_amount))
    let reciept = await tx.wait();
    if (reciept.status === 1) {
      init();
    }
  }

  //allowance
  const getAllowance = async (_active) => {
    let contract = new ethers.Contract(CONTRACTS.uns, erc20abi, signer)
    let allowance = await contract.allowance(address, _active)
    return allowance
  }

  const getStakingBal = async (_active) => {
    let contract = new ethers.Contract(_active, stakingabi, signer)
    let balance = await contract.stakes(address);
    return ethers.utils.formatEther(balance)
  }

  const getUnsBalance = async () => {
    let contract = new ethers.Contract(presale, presaleabi, signer)
    let balance = await contract.unsBalance(address)
    return ethers.utils.formatEther(balance)
  }




  // I just add this
  const [stakeButtonActive, setStakeButtonActive] = useState(true);
  // also change code on line no 128 and 145 for switching


  const SubmitEntry = () => {
    if (stakeButtonActive) {
      initiateStake(stakeInput, activeContract);
    } else if (!stakeButtonActive) {

      initializeUnstake(activeContract, stakeInput);
    }
    setStakeInput("");
  }

  const init = async () => {
    const allow = await getAllowance(activeContract);
    const stakingBal = await getStakingBal(activeContract);
    const unsBal = await getUnsBalance();
    setAllowance(String(allow));
    setUnsBalancw(String(unsBal));
    setStakingBalance(String(stakingBal));
  };

  // useEffect(() => {
  //   init();
  // }, [, stakingBalance, stake]);
  init()
  useEffect(() => {
    init();
  }, [address, stakeButtonActive, unsBalance, stakingBalance, allowance]);




  const buttonTitle = () => {
    if (stakeButtonActive) {
      if (!address) {
        return {
          title: "Connect Wallet",
          func: open,
        };
      } else if (String(allowance) == 0) {
        return {
          title: "Approve",
          func: () => {
            initializeApprove(activeContract);
          },
        };
      } else {
        return {
          title: "Stake",
          func: () => {
            SubmitEntry();
          },
        };
      }
    } else if (!stakeButtonActive) {
      if (!address) {
        return {
          title: "Connect Wallet",
          func: open,
        };
      } else {
        return {
          title: "Unstake",
          func: () => {
            SubmitEntry();
          },
        };
      }
    }
  };


  const stakeButton = buttonTitle();

  const duration = [
    {
      day: 3,
      apy: 45,
      contract: "0x54857625E6fa2183Ad412c9339f5D68e7C3c19aF",
    },
    {
      day: 6,
      apy: 90,
      contract: "0x969f7e8d8e137C461E1aF56C7EF26830CaFc087A",
    },
    {
      day: 9,
      apy: 135,
      contract: "0xD3BCB2DC5a79Fa54757114d17DBA3141c83C01B1",
    },
    {
      day: 12,
      apy: 180,
      contract: "0x54E1A93D40502aA0a3E2D57B2FdCb52DF037f3c6",
    },
  ];
  const rate = [
    {
      key: "APR:",
      value: duration[active].apy + "%",
    },
    {
      key: "Minimum stake:",
      value: "50 UNS",
    },
    {
      key: "Total Staked:",
      value: "Nan UNS",
    },
    // {
    //   key: "Total personal quote:",
    //   value: "1,000 BUS",
    // },
  ];
  return (
    <div className={styles.stakeWrapper}>
      <div className={styles.stakeUnstake}>
        <div
          className={[
            styles.stake,
            stakeButtonActive && styles.activeLock,
          ].join(" ")}
          onClick={() => setStakeButtonActive(true)}
        // I have add this for stakeactive active
        >
          <div className={styles.lockBox}>
            <img
              src={stakeButtonActive ? activeLock : lock}
              alt="#"
              className={styles.lockIcon}
            />
          </div>
          <p className={styles.heading}>Deposit UNS</p>
        </div>
        <div
          className={[
            styles.unStake,
            !stakeButtonActive && styles.activeLock,
          ].join(" ")}
          onClick={() => setStakeButtonActive(false)}
        // I have add this for unstakeActive
        >
          <div className={styles.lockBox}>
            <img
              src={!stakeButtonActive ? activeUnlock : unlock}
              alt="#"
              className={styles.lockIcon}
            />
          </div>
          <p className={styles.heading}>Un-stake</p>
        </div>
      </div>
      <Duration
        buttonTitle={buttonTitle}
        duration={duration}
        setActiveContract={setActiveContract}
        setActive={setActive}
        active={active}
      />
      <StakeAmount
        stakeInput={stakeInput}
        unsBalance={unsBalance}
        setStakeInput={setStakeInput}
      />
      <Rate stakingBalance={stakingBalance} rate={rate} />
      <button
        onClick={() => {
          stakeButton.func();
        }}
        className={styles.button}
      >
        {stakeButton.title}
      </button>
    </div>
  );
};

export default Staking;
